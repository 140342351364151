import React from "react"
import { Helmet } from "react-helmet"
import { Link } from 'gatsby'
import Layout from '../components/layout'
import {
	boxes,
	box,
	boxBack
} from '../components/boxes.module.css'
import {
	button,
	buttonDark
} from '../components/layout.module.css'

const Home = () => {
	return (
		<Layout pageTitle="Home Page">
			<Helmet>
				<script src="https://kit.fontawesome.com/11a97919e1.js" crossorigin="anonymous"></script>
			</Helmet>
			<section data-sal="slide-up" data-sal-easing="ease" data-sal-duration="500">
				<h1>
					Need a website that's super fast, simple, and affordable?<br />
					<span>Use <strong>sitekeeper</strong>.</span>
				</h1>
				<p>
					Sitekeeper websites are built on the fastest, most secure tools. If you are looking for a simple website that you don't want
					to update regularly and don't want to pay a month fee for, sitekeeper is for you. <span><strong>You pay once</strong></span>, and never have to pay for a monthly
					hosting bill again.
				</p>
				<p>
					Hosting is <span><strong>free</strong></span>.
				</p>
				<p>
					Security and speed are <span><strong>guarenteed</strong></span>.
				</p>
				<Link to="/contact/" className={button}>Get Started</Link>
			</section>
			<section className={boxes}>
				<div className={box} data-sal="slide-left" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="500">
					<i class="fa-solid fa-bolt fa-2xl"></i>
					<h3>Lightning Fast</h3>
					<div className={boxBack}>
						<p>With the power of React, Gatsbyjs, and Cloudflare, your website will remain ahead of the curve and live on the fastest network.</p>
					</div>
				</div>
				<div className={box} data-sal="slide-left" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
					<i class="fa-solid fa-shield fa-2xl"></i>
					<h3>Ultra Secure</h3>
					<div className={boxBack}>
						<p>Your website will live on the Cloudflare edge, which means it is always secure.</p>
					</div>
				</div>
				<div className={box} data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
					<i class="fa-solid fa-piggy-bank fa-2xl"></i>
					<h3>Affordable</h3>
					<div className={boxBack}>
						<p>Because your site will live on the Cloudflare edge, you will never need to pay for an annoying monthly hosting and security fee AGAIN.</p>
					</div>
				</div>
				<div className={box} data-sal="slide-left" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="500">
					<i class="fa-solid fa-magnifying-glass fa-2xl"></i>
					<h3>SEO Optimized</h3>
					<div className={boxBack}>
						<p>Because your site uses Gatsby framework, your site will perform better in search engines.</p>
					</div>
				</div>
				<div className={box} data-sal="slide-left" data-sal-delay="500" data-sal-easing="ease" data-sal-duration="500">
					<i class="fa-solid fa-cart-shopping fa-2xl"></i>
					<h3>E-Commerce</h3>
					<div className={boxBack}>
						<p>E-Commerce should not be hard. You will be able to utilize the latest and greatest E-Commerce platform called Shopify. Oh, and it's only $9/month.</p>
					</div>
				</div>
				<div className={box} data-sal="slide-left" data-sal-delay="600" data-sal-easing="ease" data-sal-duration="500">
					<i class="fa-solid fa-mobile fa-2xl"></i>
					<h3>Responsive</h3>
					<div className={boxBack}>
						<p>Your website will look beautiful on any device, screen, browser, and operating system.</p>
					</div>
				</div>
				<div className={box} data-sal="slide-left" data-sal-delay="700" data-sal-easing="ease" data-sal-duration="500">
					<i class="fa-solid fa-thumbs-up fa-2xl"></i>
					<h3>Easy To Use</h3>
					<div className={boxBack}>
						<p>Users will have no issue using and navigating your website.</p>
					</div>
				</div>
				<div className={box} data-sal="slide-left" data-sal-delay="800" data-sal-easing="ease" data-sal-duration="500">
					<i class="fa-solid fa-gear fa-2xl"></i>
					<h3>Customized</h3>
					<div className={boxBack}>
						<p>We can design and develop your website to whatever your heart desires.</p>
					</div>
				</div>
			</section>
			<section data-sal="slide-up" data-sal-easing="ease" data-sal-duration="500">
				<h2>Get the website you deserve today!</h2>
				<p>Sitekeeper is <span><strong>perfect</strong></span> for personal sites, portfolios, e-commerce stores, and small businesses.</p>
				<Link to="/contact/" className={button}>Learn More</Link>
			</section>
		</Layout>
	)
}

export default Home
